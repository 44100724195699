.select-materials-wrapper {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  padding: 0px 84px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  background-color: var(--background);

  .left-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    .loading-materials {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .add-extras-row {
      flex-shrink: 0;
      align-self: flex-end;
      height: 72px;
      padding: 24px 48px;
      display: flex;
      justify-content: flex-end;

      .add-extras {
        display: flex;
        align-items: center;
        color: var(--orange);
        font-weight: 600;
        cursor: pointer;

        &:active {
          opacity: 0.7;
        }

        > span {
          margin-left: 8px;
        }
      }
    }

    .list-container {
      width: 85%;
      padding-top: 48px;
      padding: 48px 24px;

      .list-type-title {
        padding: 12px 0px;
        color: var(--medium-gray);
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;

        &:not(:first-child) {
          margin-top: 36px;
        }
      }

      .student-line-wrapper {
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .student-name-label {
          margin-bottom: 8px;
          font-weight: 500;
        }
      }

      .bundle-item-wrapper {
        min-height: 108px;
        margin: 12px;
        padding: 12px 20px 12px 12px;
        background-color: var(--white);
        border: 4px solid var(--white);
        box-shadow: var(--shadow);
        cursor: pointer;

        &.selected {
          border: 4px solid var(--dark-primary);
        }

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        &:active {
          opacity: 0.8;
        }

        .bundle-item-content {
          height: 100%;
          display: flex;

          .preview-image {
            height: 84px;
            width: 84px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .info-container {
            flex: 1;
            display: flex;
            flex-direction: column;

            .bundle-item-title {
              font-size: 16px;
              font-weight: 500;
            }

            .bundle-item-info {
              font-size: 14px;
              color: var(--medium-gray);
            }

            .bundle-item-price {
              align-self: flex-end;
            }
          }
        }
      }

      .material-item-wrapper {
        min-height: 108px;
        padding: 12px 20px 12px 12px;
        background-color: var(--white);
        box-shadow: var(--shadow);

        &:not(:last-child) {
          margin-bottom: 16px;
        }

        .material-item-content {
          height: 100%;
          display: flex;
          align-items: center;

          .preview-image {
            height: 84px;
            width: 84px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: contain;

            &.fallback {
              background-color: #ddd;
            }
          }

          .info-container {
            flex: 1;
            align-self: stretch;
            display: flex;
            flex-direction: column;

            .material-item-title {
              font-size: 16px;
              font-weight: 500;
            }

            .material-item-info {
              font-size: 14px;
              color: var(--medium-gray);
            }

            .material-item-price {
              align-self: flex-end;
            }
          }

          .quantity-container {
            margin-left: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 28px;
            font-weight: 600;
            color: var(--dark-primary);

            > :not(:last-child) {
              margin-right: 12px;
            }

            .quantity-icon {
              cursor: pointer;

              &:active {
                opacity: 0.8;
              }

              &.disabled {
                opacity: 0.3;
                pointer-events: none;
              }
            }
          }
        }
      }
    }
  }

  .right-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hero-text {
      width: 75%;
      margin-bottom: 16px;
      color: var(--dark-primary);
      font-size: 56px;
      line-height: 60px;
      font-family: Pockota;
      font-weight: 600;
      text-align: center;
    }

    .course-logo {
      width: 180px;
      margin-bottom: 16px;
    }

    .helper-text {
      width: 75%;
      margin-top: 0px;
      margin-bottom: 32px;
      color: var(--gray);
      font-size: 20px;
      line-height: 28px;
    }

    .buttons-row {
      width: 75%;
      display: flex;
      justify-content: center;
      align-items: center;

      .cta-button-wrapper {
        flex-shrink: 0;

        :not(:last-child) {
          margin-right: 16px;
        }
      }

      .or {
        margin: 0px 24px;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .select-materials-wrapper {
    height: auto;
    min-height: var(--content-height);
    padding: 0px 20px;
    display: block;

    .right-col {
      padding-top: 36px;

      .hero-text {
        width: 100%;
        margin: 0px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 40px;
      }

      .helper-text {
        width: 100%;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }
    }

    .left-col {
      display: block;
      margin-bottom: 16px;

      &.list {
        align-items: center;
      }

      .list-container {
        width: 100%;
        padding: 3px 3px 32px;
        max-height: 100%;

        .material-item-wrapper {
          .material-item-content {
            flex-direction: column;

            .item-info {
              display: flex;
            }

            .bottom-row {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;

              .material-item-price {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .buttons-row {
      width: 100%;
      padding-bottom: 36px;

      > * {
        width: 100%;
      }

      :not(:last-child) {
        margin-bottom: 16px;
      }

      .cta-button-wrapper {
        > * {
          width: 100%;
        }
      }
    }
  }
}
