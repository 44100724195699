.teacher-details-wrapper {
  position: relative;
  min-height: var(--content-height);
  width: 100vw;
  padding: 48px 84px;
  background-color: var(--background);

  &.teacher-details-loading {
    display: flex;
    flex-direction: column;

    .teacher-details-spinner-wrapper {
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .back-link-row {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .back-link {
      display: inline-flex;
      align-items: center;
      color: var(--orange);
      font-size: 20px;
      font-weight: 500;
    }
  }
}

@media screen and (max-width: 568px) {
  .teacher-details-wrapper {
    padding: 24px;

    .back-link-row {
      width: 100%;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;

      &.double {
        flex-direction: column;

        > :first-child {
          margin-bottom: 16px;
        }

        .back-link {
          font-size: 16px;
        }
      }

      .back-link {
        display: inline-flex;
        align-items: center;
        color: var(--orange);
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}
