html {
  --header-height: 84px;

  --content-height: calc(100vh - var(--header-height));
  --content-width: 100vw;
}

* {
  box-sizing: border-box;
}
