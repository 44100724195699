.map-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .map-legend {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .legend-line {
      display: flex;
      align-items: center;

      .legend-circle {
        height: 12px;
        width: 12px;
        margin-right: 6px;
        border-radius: 50%;

        &.sb {
          background-color: var(--Sound-Beginnings);
        }
        &.lpm {
          background-color: var(--Lets-Play-Music);
        }
        &.presto {
          background-color: var(--Presto);
        }
      }
    }
  }
}
