.forgot-password {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  padding: 48px 84px 84px;
  display: flex;
  background-color: var(--background);

  .left-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hero-text {
      width: 75%;
      margin-bottom: 16px;
      color: var(--dark-primary);
      font-size: 60px;
      line-height: 60px;
      font-family: Pockota;
      font-weight: 600;
      text-align: center;

      + .hero-text {
        margin-top: -12px;
      }
    }

    .helper-text {
      width: 75%;
      margin-top: 0px;
      margin-bottom: 32px;
      color: var(--gray);
      font-size: 20px;
      line-height: 28px;
    }

    .buttons-row {
      width: 75%;
      display: flex;
      align-items: center;

      .cta-button-wrapper {
        flex-shrink: 0;
      }

      .or {
        margin: 0px 24px;
      }
    }
  }

  .right-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .auth-content {
      position: relative;
      z-index: 1;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .auth-square {
        // height: 440px;
        width: 382px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: var(--big-shadow);

        .auth-logo {
          width: 70%;
        }

        .auth-inputs {
          width: 322px;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .send-code-button {
          width: 322px;
          margin-top: 20px;
        }

        .lpm-input.error + .send-code-button {
          margin-top: 44px;
        }

        .forgot-password-link {
          margin-top: 16px;
          font-size: 14px;
          font-weight: 800;
          line-height: 22px;
          cursor: pointer;
          user-select: none;

          &:active {
            opacity: 0.2;
          }

          a {
            color: var(--orange);
          }
        }

        .no-account {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 64px;
          margin-bottom: 24px;
          font-size: 14px;
          font-weight: 800;
          line-height: 22px;
          user-select: none;

          > span {
            color: initial;
            opacity: 0.3;
          }

          .sign-up-button {
            width: 322px;
            margin-top: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .forgot-password {
    height: auto;
    min-height: var(--content-height);
    padding: 0px 20px;
    display: block;

    .left-col {
      padding-top: 36px;

      .hero-text {
        width: 100%;
        margin: 0px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 40px;
      }

      .helper-text {
        width: 100%;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }
    }

    .right-col {
      display: block;
      margin-bottom: 16px;

      .auth-content {
        .auth-square {
          width: 90vw;
          margin-top: 8px;

          .auth-inputs {
            width: calc(100% - 40px);
          }

          .send-code-button {
            width: 100%;
          }

          .lpm-input-error + .auth-button {
            margin-top: 8px;
          }

          .no-account {
            width: calc(100% - 40px);
            margin-top: 36px;

            .sign-up-button {
              width: 100%;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
