.teacher-listing {
  padding: 16px 20px 20px;
  background-color: var(--white);
  box-shadow: var(--shadow);

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  .teacher-listing-content {
    .top-row {
      width: calc(100% + 40px);
      padding: 0px 20px 16px;
      margin-left: -20px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid var(--light-gray);

      .profile-picture-wrapper {
        height: 108px;
        width: 108px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        .profile-picture {
          height: 100%;
          width: 100%;
          border-radius: 4px;
          box-shadow: var(--big-shadow);
          object-fit: cover;
        }
      }

      .teacher-info {
        position: relative;
        flex: 1;
        height: 108px;
        padding-bottom: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .teacher-name {
          font-size: 24px;
        }

        .distance {
          margin-top: 2px;
          font-size: 14px;
          color: var(--medium-gray);

          .studio-location {
            margin-right: 8px;
          }
        }

        .contact-button {
          margin-top: 4px;
          color: var(--orange);
          font-size: 18px;
          font-weight: 500;
        }

        .background-check {
          margin-top: 2px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: var(--medium-gray);

          > svg {
            margin-right: 8px;
            color: var(--dark-primary);
            stroke-width: 3;
          }
        }

        .links {
          position: absolute;
          bottom: 0;
          width: calc(100% + 16px);
          min-height: 20px;
          max-height: 48px;
          align-self: stretch;
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          line-height: 20px;

          .link {
            color: var(--orange);
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            cursor: pointer;

            &:not(:last-child) {
              margin-right: 12px;
            }

            &:active {
              opacity: 0.7;
            }
          }
        }
      }
    }

    .class-listings-wrapper {
      margin-top: 16px;

      .no-classes-zero-state {
        color: var(--medium-gray);
      }

      .location-group-wrapper {
        padding-top: 12px;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0px;
        }

        .location-name {
          margin-bottom: 6px;
          font-size: 14px;
          color: var(--medium-gray);
        }
      }

      .curriculum-section {
        &:not(:last-child) {
          padding-bottom: 20px;
          margin-bottom: 16px;
          border-bottom: 1px solid var(--light-gray);

          &.collapsed {
            padding-bottom: 4px;
          }
        }

        .title-row {
          margin-bottom: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .left-items {
            display: flex;
            align-items: center;

            .curriculum-logo {
              height: 36px;
              width: 36px;
              margin-right: 12px;
            }

            .left-titles {
              display: flex;
              align-items: baseline;

              .course-title {
                font-size: 20px;
                color: var(--medium-gray);
                text-transform: uppercase;
                letter-spacing: 0.75px;
              }

              .sample-class-button {
                margin-left: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--orange);

                span {
                  font-size: 16px;
                  font-weight: 500;
                  cursor: pointer;

                  &:active {
                    opacity: 0.8;
                  }
                }
              }
            }
          }

          .right-items {
            display: flex;
            align-items: center;
            font-size: 18px;
            color: var(--orange);
            text-transform: uppercase;
            letter-spacing: 0.75px;
            cursor: pointer;
            user-select: none;

            &:active {
              opacity: 0.8;
            }

            .collapse-link-icon {
              margin-top: 1px;
            }
          }
        }

        .classes-list {
          margin: 0px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-template-rows: repeat(auto-fill, 1fr);
          gap: 20px;

          &.collapsed {
            display: none;
            visibility: hidden;
          }

          .class-item {
            background-color: #e4e4e4;
            padding: 8px 12px;
            border-radius: 4px;
            cursor: pointer;

            &.soundbeginnings {
              background-color: var(--semi-transparent-orange);
            }

            &.letsplaymusic {
              background-color: var(--semi-transparent-green);
            }

            &.presto {
              background-color: var(--semi-transparent-purple);
            }

            &.disabled {
              opacity: 0.4;
              pointer-events: none;
            }

            &:active {
              opacity: 0.8;
            }

            > .class-item-contents {
              width: 100%;

              .session-period {
                // color: var(--medium-gray);
                font-size: 14px;
              }

              .seats-available {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .teacher-listing {
    padding: 8px 12px;
    background-color: var(--white);
    box-shadow: var(--shadow);

    &:not(:last-child) {
      margin-bottom: 32px;
    }

    .teacher-listing-content {
      .top-row {
        width: calc(100% + 24px);
        padding: 0px 16px 8px;
        margin-left: -12px;

        .profile-picture-wrapper {
          height: 96px;
          width: 96px;
        }

        .teacher-info {
          position: relative;
          flex: 1;
          height: 108px;
          padding-bottom: 24px;
          display: flex;
          flex-direction: column;
          justify-content: center;

          .teacher-name {
            font-size: 16px;
            font-weight: 600;
          }

          .distance {
            margin-top: 2px;
            font-size: 12px;
            color: var(--medium-gray);

            .studio-location {
              display: flex;
              flex-direction: column;
              margin-right: 8px;
            }
          }

          .contact-button {
            font-size: 13px;
            font-weight: 600;
          }

          .links {
            bottom: 7px;
            height: 14px;
            line-height: 14px;

            .link {
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
            }
          }
        }
      }

      .curriculum-sections-wrapper {
        .curriculum-section {
          .title-row {
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .left-items {
              display: flex;
              align-items: center;

              .curriculum-logo {
                height: 36px;
                width: 36px;
                margin-right: 12px;
              }

              .left-titles {
                display: flex;
                align-items: baseline;

                .course-title {
                  font-size: 14px;
                  color: var(--medium-gray);
                  text-transform: uppercase;
                  letter-spacing: 0.75px;
                }

                .sample-class-button {
                  display: none;
                }
              }
            }

            .right-items {
              font-size: 14px;

              &:active {
                opacity: 0.8;
              }

              .collapse-link-icon {
                margin-top: 0.5px;
                transform: scale(0.8);
              }
            }
          }

          .classes-list {
            grid-template-columns: repeat(2, 1fr);

            .class-item {
              > .class-item-contents {
                font-size: 14px;
                font-weight: 600;

                .session-period {
                  font-size: 12px;
                  font-weight: 400;
                }

                .seats-available {
                  font-size: 12px;
                  font-weight: 400;
                }
              }
            }
          }
        }
      }
    }
  }
}
