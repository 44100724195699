body {
  margin: 0;
  overflow: visible;
  overscroll-behavior: none;
}

.backer {
  position: fixed;
  top: -200vh;
  height: 500vh;
  width: 100vw;
  background-image: linear-gradient(var(--background), var(--background) 50%, #222 50.1%, #222 150%);
}

a {
  text-decoration: none;
  color: initial;
}

.loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);
}

.main-content {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  display: flex;
  z-index: 1;
}

.error-page {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding: 84px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);
  overflow: hidden;

  .title {
    width: 100%;
    margin-bottom: 16px;
    color: var(--dark-primary);
    font-size: 56px;
    line-height: 60px;
    font-family: Pockota;
    font-weight: 600;
    text-align: center;
  }

  .message {
    width: 75%;
    margin-top: 0px;
    margin-bottom: 32px;
    color: var(--gray);
    font-size: 20px;
    line-height: 28px;
    text-align: center;
  }
}

@media screen and (max-width: 568px) {
  .error-page {
    padding: 48px 20px;
    box-sizing: border-box;

    .title {
      width: calc(100vw - 40px);
      margin: 0px;
      margin-bottom: 24px;
      font-size: 40px;
      line-height: 40px;
    }

    .message {
      width: calc(100vw - 40px);
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }
}
