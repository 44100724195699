#cart-menu-container .cart-menu {
  position: fixed;
  top: calc(0.75 * var(--header-height) + 6px);
  right: calc(84px + 6px);
  width: 420px;
  max-height: 85vh;
  padding: 16px 12px;
  background-color: var(--white);
  box-shadow: var(--big-shadow);
  overflow-y: scroll;
  z-index: 11;

  .zero-state {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--medium-gray);
    font-size: 18px;
    font-weight: 500;
  }

  .cart-menu-header {
    margin-bottom: 12px;
    color: var(--medium-gray);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .enrollment-item {
    &:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--light-gray);
    }

    .title {
      margin-bottom: 2px;
    }

    .student-name {
      margin-bottom: 4px;
      color: var(--medium-gray);
      font-size: 14px;
    }

    .total {
      color: var(--medium-gray);
      font-size: 14px;
    }

    .action-buttons-wrapper {
      padding-top: 12px;
      width: 100%;
      display: flex;
      justify-content: flex-end;

      > :not(:last-child) {
        margin-right: 12px;
      }
    }
  }

  .checkout-section {
    width: 100%;
    padding-top: 8px;
    margin-bottom: 24px;

    .shipping-total,
    .total {
      margin-bottom: 4px;
      color: var(--gray);
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .total {
      margin-bottom: 16px;
    }

    .checkout-button-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;

      .checkout-button {
        width: 90%;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  #cart-menu-container .cart-menu {
    top: calc(0.75 * var(--header-height) + 6px);
    right: 12px;
    width: calc(100vw - 24px);
    max-width: 420px;
  }
}

@media screen and (max-width: 380px) {
  #cart-menu-container .cart-menu .lpm-button {
    font-size: 12px;
  }
}

@media screen and (max-width: 360px) {
  #cart-menu-container .cart-menu .lpm-button {
    font-size: 11px;
  }
}
