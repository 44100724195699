.teacher-testimonials-modal-wrapper {
  width: 100%;
  padding: 0px calc((100% - 650px) / 2);
  text-align: center;
  pointer-events: none;
}

.teacher-testimonials-modal {
  position: relative;
  width: 650px;
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  pointer-events: all;

  .content {
    height: calc(100% - 60px);

    .title {
      margin-bottom: 16px;
      font-size: 32px;
    }

    .testimonials-wrapper {
      height: calc(100% - 40px - 60px);
      overflow-y: scroll;

      .testimonial {
        margin-bottom: 32px;

        .testimonial-text {
          margin-bottom: 6px;
          text-align: left;
        }

        .testimonial-attribution {
          text-align: end;
          font-weight: 500;
        }
      }
    }
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .modal-button {
      height: 40px;
      width: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: var(--main);
      color: #fff;
      outline: none;
      border: none;
      border-radius: 2px;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.5px;
      font-weight: 700;
      cursor: pointer;

      &:active {
        opacity: 0.9;
      }

      &.prev {
        margin-left: 24px;
        background-color: #fff;
        color: var(--main);
        border: 2px solid var(--main);
      }

      &.cancel {
        margin-left: 24px;
        background-color: #fff;
        color: var(--darkGray);
        border: 2px solid var(--darkGray);
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .teacher-testimonials-modal-wrapper {
    width: auto;
  }

  .teacher-testimonials-modal {
    width: auto;
    max-width: calc(100vw - 40px);
    padding: 12px 24px;

    .content {
      .title {
        margin-bottom: 16px;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }
}
