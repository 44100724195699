.thank-you-wrapper {
  position: relative;
  width: 100vw;
  min-height: var(--content-height);
  padding: 48px 84px 84px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);

  .content {
    max-width: 650px;
  }

  .title {
    width: 100%;
    margin-bottom: 16px;
    color: var(--dark-primary);
    font-size: 56px;
    line-height: 60px;
    font-family: Pockota;
    font-weight: 600;
    text-align: center;
  }

  .registration-items {
    width: 100%;
    margin-bottom: 32px;
    display: flex;
    justify-content: center;
  }

  .registration-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    &:not(:last-child) {
      margin-right: 48px;
    }

    .bold {
      font-weight: 500;
    }

    .course-logo {
      width: 240px;
      margin-bottom: 8px;
      object-fit: cover;
    }

    .registration-info {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .aux {
      color: var(--medium-gray);
      font-size: 14px;
    }
  }

  .carousel {
    &.carousel-slider {
      overflow: initial;

      .control-arrow {
        color: var(--medium-gray);
        opacity: 1;

        &:hover {
          background: rgba(0, 0, 0, 0.05);
        }

        &.control-prev:before {
          border-right: 8px solid var(--medium-gray);
        }

        &.control-next:before {
          border-left: 8px solid var(--medium-gray);
        }
      }
    }

    .carousel-status {
      display: none;
      color: var(--medium-gray);
      text-shadow: none;
    }

    .control-dots {
      bottom: -44px;

      .dot {
        box-shadow: none;
        background-color: var(--medium-gray);
      }
    }
  }

  .message {
    margin-top: 24px;
    font-size: 28px;
    text-align: center;
  }

  .buttons-row {
    margin-top: 48px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .materials-reminder {
    margin-top: 36px;
    color: var(--medium-gray);
    text-align: center;
  }
}

@media screen and (max-width: 568px) {
  .thank-you-wrapper {
    position: relative;
    padding: 36px 20px;

    .content {
      max-width: 100%;
    }

    .title {
      width: 100%;
      margin: 0px;
      margin-bottom: 12px;
      font-size: 40px;
      line-height: 40px;
    }

    .carousel-root {
      width: calc(100% + 40px);
      margin-left: -20px;
      padding-bottom: 24px;
    }

    .registration-item {
      .course-logo {
        width: 55vw;
      }
    }

    .message {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 24px;
    }

    .buttons-row {
      width: 100%;
      margin-top: 24px;
      flex-direction: column;

      a {
        width: 100%;

        + .lpm-button {
          margin-top: 16px;
        }

        .lpm-button {
          margin-left: 20px;
        }
      }

      .lpm-button {
        width: calc(100% - 40px);
      }
    }

    .materials-reminder {
      margin-top: 24px;
    }
  }
}
