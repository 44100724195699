.coupon-item {
  margin: 0px 24px 24px 16px;
  padding: 12px;
  background-color: var(--white);
  box-shadow: var(--shadow);
  width: max-content;

  .discount-container {
    display: flex;
    align-items: center;
    gap: 12px;

    .discount-input {
      width: 200px;
      min-height: 40px;
      border: 1px solid var(--light-gray);
      border-radius: 4px;
      padding: 0px 12px;
    }

    button {
      min-width: max-content;
    }
  }

  .message {
    margin-top: 8px;
    font-size: 14px;
    color: var(--medium-gray);
  }
}

@media screen and (max-width: 568px) {
  .coupon-item {
    margin: 0px 0px 24px 0px;
    padding: 12px;
    width: 100%;
  }
}
