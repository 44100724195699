.payment-information {
  .payment-method-select-wrapper {
    max-width: 480px;
  }

  .new-card-info-wrapper {
    .input-row {
      width: 100%;
      max-width: 480px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .input-wrapper {
        flex: 1;

        .input-title {
          margin-bottom: 8px;
        }

        input::placeholder {
          color: var(--medium-gray);
          opacity: 0.5;
        }
      }
    }

    .use-existing-card {
      max-width: 540px;
      margin-top: 12px;
      margin-bottom: 36px;
      color: var(--orange);
      font-weight: 500;
      text-align: right;

      span {
        cursor: pointer;

        &:active {
          opacity: 0.8;
        }
      }
    }
  }

  .checkout-error {
    margin-top: 24px;
    color: var(--red);

    &.new-card {
      margin-top: -16px;
      margin-bottom: 36px;
    }
  }

  .no-purchase-necessary {
    color: var(--medium-gray);
    font-size: 18px;
    text-align: center;
  }
}

@media screen and (max-width: 568px) {
  .payment-information {
    .new-card-info-wrapper {
      .input-wrapper {
        position: relative;
        width: 100%;

        .mobile-card-input-wrapper,
        .mobile-card-aux-inputs-wrapper {
          width: 100%;
          margin-bottom: 12px;
          align-items: center;
          background-color: white;
          display: flex;
          height: 40px;
          padding: 0px 8px;
          box-sizing: border-box;
          border-radius: 2px;
          box-shadow: var(--shadow);

          input {
            flex: 1;
            font-size: 16px;
            line-height: 34px;
            outline: none;
            border: none;

            &::placeholder {
              color: var(--medium-gray);
              opacity: 0.5;
            }
          }
        }

        .mobile-card-input-wrapper {
          > svg {
            margin-right: 8px;
          }
        }

        .mobile-card-aux-inputs-wrapper {
          > input {
            width: calc((100% - 48px) / 3);
          }
        }

        .mobile-card-error-text {
          position: absolute;
          bottom: -12px;
          color: var(--red);
          font-size: 14px;
        }
      }

      .use-existing-card {
        margin-top: 8px;
        margin-bottom: 0px;
      }
    }
  }
}

.keap-payment-actions {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  max-width: 480px;
}

.keap-submit-button {
  background-color: var(--orange);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-bottom: 16px;
  transition: all 0.2s;
  box-shadow: var(--shadow);
  text-align: center;
  width: 100%;

  &:disabled {
    background-color: var(--medium-gray);
    cursor: not-allowed;
    transform: none;
    opacity: 0.7;
  }
}

.keap-submit-button:hover {
  background-color: var(--dark-primary);
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.keap-submit-button:active {
  transform: translateY(0);
}

@media screen and (max-width: 568px) {
  .keap-payment-actions {
    margin-top: 20px;
  }
  
  .keap-submit-button {
    padding: 14px 20px;
    font-size: 18px;
  }
}

/* Add styles for payment method success */
.payment-method-success {
  display: flex;
  align-items: center;
  margin-top: 15px;
  padding: 12px;
  background-color: rgba(85, 143, 55, 0.1);
  border-left: 3px solid var(--dark-primary);
  border-radius: 4px;
  
  .success-icon {
    color: var(--dark-primary);
    font-size: 20px;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .success-message {
    color: var(--dark-green);
    font-weight: 500;
  }
}
