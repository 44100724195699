.header {
  position: relative;
  height: var(--header-height);
  width: var(--content-width);
  padding: 12px 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
  z-index: 10;
  overflow-y: visible;

  .logo-banner {
    height: 80%;
    cursor: pointer;
  }

  .right-items {
    display: flex;

    .profile-button,
    .cart-button {
      --size: calc(0.5 * var(--header-height));

      position: relative;
      height: var(--size);
      width: var(--size);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      color: var(--dark-primary);
      border: 2px solid var(--dark-primary);
      border-radius: 50%;
      cursor: pointer;

      &:not(:last-child) {
        margin-right: 12px;
      }

      &:active {
        opacity: 0.8;
      }

      &.open {
        color: var(--white);
        background-color: var(--dark-primary);
      }

      .profile-picture {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 50%;
        box-shadow: var(--shadow);
      }

      .profile-button-icon,
      .cart-button-icon {
        margin-left: -1px;
      }

      .items-in-cart-indicator {
        position: absolute;
        top: -1px;
        right: -1px;
        height: 12px;
        width: 12px;
        background-color: var(--orange);
        border-radius: 50%;
      }
    }

    .profile-menu-content {
      position: absolute;
      top: calc(100% - 8px);
      right: 8px;
      // min-height: 100px;
      width: 180px;
      display: flex;
      flex-direction: column-reverse;
      background-color: var(--white);
      border-radius: 4px;
      box-shadow: var(--big-shadow);
      cursor: default;

      .profile-menu-header {
        padding: 12px;
        padding-bottom: 4px;
        color: var(--medium-gray);
        font-size: 12px;
        text-transform: uppercase;
      }

      .profile-menu-section {
        margin-bottom: 12px;

        .profile-menu-item {
          padding: 6px 12px;
          color: var(--dark-gray);
          font-size: 14px;
          font-weight: 500;

          &.action {
            cursor: pointer;
          }

          &:hover {
            background-color: var(--super-light-gray);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .header {
    padding: 12px 20px;

    .right-items .profile-button .profile-menu-content {
      position: absolute;
      top: calc(100% + 8px);
      right: 0px;
      width: 280px;

      .lpm-button {
        width: calc(100% - 24px);
        margin: 12px;
        margin-bottom: 8px;
      }
    }
  }
}
