.geo-search-wrapper {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  padding: 0px 84px;
  display: flex;
  background-color: var(--background);

  .left-col {
    flex: 1;
    padding-top: 132px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hero-text {
      width: 75%;
      margin-bottom: 16px;
      color: var(--dark-primary);
      font-size: 56px;
      line-height: 60px;
      font-family: Pockota;
      font-weight: 600;
      text-align: center;
    }

    .helper-text {
      width: 75%;
      margin-top: 0px;
      margin-bottom: 32px;
      color: var(--gray);
      font-size: 20px;
      line-height: 28px;
    }

    .buttons-row {
      width: 75%;
      display: flex;
      align-items: center;

      .cta-button-wrapper {
        flex-shrink: 0;
      }

      .or {
        margin: 0px 24px;
      }
    }

    .couldnt-find-link {
      margin-top: 24px;
    }
  }

  .right-col {
    flex: 1.2;
    display: flex;
    flex-direction: column;

    &.list {
      align-items: center;
    }

    .results-header-row {
      height: 72px;
      width: 100%;
      padding: 24px 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 600;

      .total-results {
        color: var(--dark-primary);
      }

      .toggle-view {
        color: var(--orange);
        cursor: pointer;

        &:active {
          opacity: 0.7;
        }
      }
    }

    .map-container {
      position: relative;
      flex: 1;
      padding-bottom: 32px;

      .map-loading-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        height: calc(100% - 32px - 72px);
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--overlay);

        &.passthrough {
          pointer-events: none;
        }

        .no-results-message {
          max-width: 65%;
          color: var(--white);
          font-size: 24px;
          line-height: 32px;
          text-align: center;
          opacity: 0.8;
        }
      }
    }

    .list-container {
      width: 100%;
      padding: 32px 3px;
      max-height: 100%;
      overflow-y: scroll;
    }
  }
}

@media screen and (max-width: 568px) {
  .geo-search-wrapper {
    height: auto;
    min-height: var(--content-height);
    padding: 0px 20px;
    display: block;

    .left-col {
      padding-top: 36px;

      .hero-text {
        width: 100%;
        margin: 0px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 40px;
      }

      .helper-text {
        width: 100%;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }

      .buttons-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cta-button-wrapper {
          width: 100%;
          flex-shrink: 0;

          .location-button {
            width: 100%;
          }
        }

        .or {
          margin: 16px 0px;
        }
      }
    }

    .right-col {
      display: block;
      padding-bottom: 32px;

      &.list {
        align-items: center;
      }

      .results-header-row {
        width: 100%;
        padding: 0px;
        margin-bottom: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;

        .total-results {
          display: flex;
          flex-direction: column;
        }
      }

      .map-container {
        position: relative;
        height: 480px;
        min-height: 480px;
        flex: 1;
        padding-bottom: 32px;

        .map-loading-overlay {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          height: calc(100% - 32px - 72px);
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--overlay);
        }
      }

      .list-container {
        width: 100%;
        padding: 3px 3px 32px;
        max-height: 100%;
        overflow-y: scroll;
      }
    }
  }
}
