.reset-password-success {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  padding: 48px 84px 84px;
  display: flex;
  background-color: var(--background);

  .left-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hero-text {
      width: 75%;
      margin-bottom: 16px;
      color: var(--dark-primary);
      font-size: 60px;
      line-height: 60px;
      font-family: Pockota;
      font-weight: 600;
      text-align: center;
    }

    .helper-text {
      width: 75%;
      margin-top: 0px;
      margin-bottom: 32px;
      color: var(--gray);
      font-size: 20px;
      line-height: 28px;
      text-align: center;
    }
  }
}

@media screen and (max-width: 568px) {
  .reset-password-success {
    height: auto;
    min-height: var(--content-height);
    padding: 0px 20px;
    display: block;

    .left-col {
      padding-top: 36px;

      .hero-text {
        width: 100%;
        margin: 0px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 40px;
      }

      .helper-text {
        width: 100%;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }
    }
  }
}
