.account-error-page {
  position: relative;
  width: 100vw;
  min-height: 100vh;
  padding: 84px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--background);
  overflow: hidden;

  .title {
    width: 100%;
    margin-bottom: 16px;
    color: var(--dark-primary);
    font-size: 56px;
    line-height: 60px;
    font-family: Pockota;
    font-weight: 600;
    text-align: center;
  }

  .message {
    width: 75%;
    margin-top: 0px;
    margin-bottom: 32px;
    color: var(--gray);
    font-size: 20px;
    line-height: 28px;
    text-align: center;

    a {
      color: var(--orange);
    }
  }
}

@media screen and (max-width: 568px) {
  .account-error-page {
    padding: 48px 20px;
    box-sizing: border-box;

    .title {
      width: calc(100vw - 40px);
      margin: 0px;
      margin-bottom: 24px;
      font-size: 40px;
      line-height: 40px;
    }

    .message {
      width: calc(100vw - 40px);
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
    }
  }
}
