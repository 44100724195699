@import "./fonts/index.scss";

html {
  --dark-primary: #558f37;
  --light-primary: #cbeabc;

  --orange: #ff8935;
  --semi-transparent-orange: #ff893526;

  --green: #82cd55;
  --semi-transparent-green: #82cd5526;

  --purple: #8e8df0;
  --semi-transparent-purple: #8e8df026;

  --red: #ff5b60;
  --yellow: #ffb900;
  --blue: #6cd0fa;

  --female-pink: #ee92ac;
  --female-pink-semi-transparent: #ee92ac33;

  --male-blue: #73c5e9;
  --male-blue-semi-transparent: #73c5e933;

  --black: #000;
  --white: #fff;

  --dark-gray: #3b3b3b;
  --medium-gray: #959499;
  --light-gray: #dddddd;
  --super-light-gray: #f7f6f6;

  --background: #edf5e5;
  --overlay: #00000044;

  --shadow-color: rgba(0, 0, 0, 0.05);
  --shadow: 0px 0.5px 2px 2px var(--shadow-color);
  --big-shadow: 0px 1px 4px 4px var(--shadow-color);

  --Sound-Beginnings: #ff8935;
  --Lets-Play-Music: #82cd55;
  --Presto: #8e8df0;

  body {
    color: var(--dark-gray);
  }

  * {
    font-family: "Lexend Deca", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    input::-webkit-input-placeholder,
    input:-ms-input-placeholder,
    input:-moz-placeholder,
    input::-moz-placeholder,
    input::placeholder {
      font-family: "Lexend Deca", sans-serif;
    }
  }
}
