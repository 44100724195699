.list-search-wrapper {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  padding: 48px 84px 0px;
  display: flex;
  background-color: var(--background);

  .left-col {
    flex: 1;
    padding-top: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hero-text {
      width: 75%;
      margin-bottom: 16px;
      color: var(--dark-primary);
      font-size: 56px;
      line-height: 60px;
      font-family: Pockota;
      font-weight: 600;
      text-align: center;
    }

    .helper-text {
      width: 75%;
      margin-top: 0px;
      margin-bottom: 32px;
      color: var(--gray);
      font-size: 20px;
      line-height: 28px;
    }

    .buttons-row {
      width: 75%;
      display: flex;
      align-items: center;

      .cta-button-wrapper {
        flex-shrink: 0;
      }

      .or {
        margin: 0px 24px;
      }
    }
  }

  .right-col {
    flex: 1.2;

    &.list {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .search-input-container {
      flex-shrink: 0;
      width: 100%;
    }

    .list-container {
      width: 100%;
      max-height: 100%;
      padding: 24px 0px;
      overflow-y: scroll;

      .loading-wrapper {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .zero-state-wrapper {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;

        .zero-state-text {
          font-size: 24px;
          color: var(--medium-gray);
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .list-search-wrapper {
    height: auto;
    min-height: var(--content-height);
    padding: 0px 20px;
    display: block;

    .left-col {
      padding-top: 36px;

      .hero-text {
        width: 100%;
        margin: 0px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 40px;
      }

      .helper-text {
        width: 100%;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }
    }

    .right-col {
      display: block;

      .list-container {
        width: 100%;
        max-height: 100%;
        padding: 32px 3px 32px;

        .zero-state-wrapper {
          height: 100px;
          .zero-state-text {
            font-size: 18px;
          }
        }

        .loading-wrapper {
          height: 100px;
        }
      }
    }
  }
}
