.select-students-wrapper {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  padding: 48px 84px 84px;
  display: flex;
  background-color: var(--background);

  .left-col {
    flex: 1;
    padding-top: 84px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hero-text {
      width: 75%;
      margin-bottom: 16px;
      color: var(--dark-primary);
      font-size: 56px;
      line-height: 60px;
      font-family: Pockota;
      font-weight: 600;
      text-align: center;
    }

    .helper-text {
      width: 75%;
      margin-top: 0px;
      margin-bottom: 32px;
      color: var(--gray);
      font-size: 20px;
      line-height: 28px;
    }

    .buttons-row {
      width: 75%;
      display: flex;
      align-items: center;

      .cta-button-wrapper {
        flex-shrink: 0;
      }

      .or {
        margin: 0px 24px;
      }
    }
  }

  .right-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .loading-children {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .add-child-row {
      flex-shrink: 0;
      align-self: flex-end;
      height: 72px;
      padding: 24px 48px;
      display: flex;
      justify-content: flex-end;

      .add-child {
        display: flex;
        align-items: center;
        color: var(--orange);
        font-weight: 600;
        cursor: pointer;

        &:active {
          opacity: 0.7;
        }

        > span {
          margin-left: 8px;
        }
      }
    }

    .list-container {
      width: 85%;
      padding: 8px 24px;
      max-height: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: grid-auto-flow;
      gap: 16px;

      .child-item-wrapper {
        padding: 10px 18px;
        background-color: var(--white);
        box-shadow: var(--shadow);
        border: 4px solid var(--white);
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &.selected {
          border: 4px solid var(--dark-primary);
          opacity: 1;
          cursor: pointer;
        }

        &:active {
          opacity: 0.8;
        }

        .child-item-content {
          display: flex;
          align-items: center;

          .profile-image {
            height: 64px;
            width: 64px;
            margin-right: 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: #ddd;

            &.female {
              background-color: var(--female-pink-semi-transparent);
            }

            &.male {
              background-color: var(--male-blue-semi-transparent);
            }
          }

          .info-container {
            .child-item-name {
              font-size: 16px;
              font-weight: 500;
            }

            .child-item-birthday {
              font-size: 12px;
              color: var(--medium-gray);
            }

            .person-type-chips-container {
              margin-top: 6px;
              display: flex;

              .chip {
                height: 20px;
                margin-right: 6px;
                padding: 6px 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: var(--white);
                border-radius: 10px;
                font-size: 14px;
                line-height: 16px;

                &.parent {
                  background-color: var(--purple);
                }

                &.child {
                  background-color: var(--orange);
                }

                &.teacher {
                  background-color: var(--blue);
                }
              }
            }
          }
        }

        .match-explanation {
          margin-top: 8px;
          color: var(--medium-gray);
          font-size: 12px;
        }
      }
    }

    .no-more-seats {
      margin-top: 24px;
      text-align: center;
      color: var(--red);
      font-weight: 500;
    }

    .buttons-row {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      .cta-button-wrapper {
        flex-shrink: 0;

        :not(:last-child) {
          margin-right: 16px;
        }
      }

      .or {
        margin: 0px 24px;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .select-students-wrapper {
    height: auto;
    min-height: var(--content-height);
    padding: 0px 20px;
    display: block;

    .left-col {
      padding-top: 36px;

      .hero-text {
        width: 100%;
        margin: 0px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 40px;
      }

      .helper-text {
        width: 100%;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }

      .buttons-row {
        width: 100%;
        margin-bottom: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .cta-button-wrapper {
          width: 100%;
          flex-shrink: 0;

          .location-button {
            width: 100%;
          }
        }

        .or {
          margin: 16px 0px;
        }
      }
    }

    .right-col {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;

      .loading-children {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .list-container {
        width: 100%;
        padding: 8px 24px;
        grid-template-columns: 1fr;
      }

      .add-child-row {
        align-self: initial;
        height: auto;
        padding: 24px 48px 0px;
      }

      .buttons-row {
        margin-top: 32px;
        margin-bottom: 36px;
      }
    }
  }
}
