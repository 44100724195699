.personas-wrapper {
  position: relative;
  min-height: var(--content-height);
  width: 100vw;
  padding: 48px 84px 48px;
  display: flex;
  flex-direction: column;
  background-color: var(--background);

  .title {
    width: 100%;
    margin: 32px 0px 64px;
    color: var(--dark-primary);
    font-size: 56px;
    line-height: 60px;
    font-family: Pockota;
    font-weight: 600;
    text-align: center;
  }

  .persona-squares-row {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    gap: 84px;

    .persona-square-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .persona-square {
        height: 100%;
        width: 100%;
        padding: 12px;
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border-radius: 16px;
        box-shadow: var(--big-shadow);
        cursor: pointer;
        user-select: none;

        &:active {
          opacity: 0.9;
        }

        .persona-icon-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;

          .persona-studio-image {
            width: 100%;
            height: 260px;
            margin: -8px 0px;
            object-fit: contain;
          }
        }

        .persona-title {
          font-size: 28px;
          text-align: center;
        }

        .persona-description {
          margin: 20px 0px;
          color: var(--medium-gray);
          text-align: center;
        }
      }
    }
  }

  .not-sure-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;

    a:not(:first-child) {
      margin-left: 48px;
    }
  }
}

@media screen and (max-width: 568px) {
  .personas-wrapper {
    height: auto;
    padding: 48px 20px 48px;

    .title {
      margin: 0px 0px 48px;
      font-size: 48px;
      line-height: 56px;
    }

    .persona-squares-row {
      height: 100%;
      display: initial;

      .persona-square-wrapper {
        &:not(:last-child) {
          margin-bottom: 48px;
        }
      }
    }

    .not-sure-row {
      flex-direction: column;
      align-items: center;
    }

    a:not(:first-child) {
      margin-top: 24px;
      margin-left: 0px !important;
    }

    .not-sure-link {
      height: auto;
      min-height: 40px;
    }
  }
}
