.checkout-late-fees-item {
  margin: 0px 24px 0px 16px;
  padding: 12px;
  background-color: var(--white);
  box-shadow: var(--shadow);

  &:not(:last-child) {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--light-gray);
  }

  .late-fees-item-title {
    margin-bottom: 6px;
    font-size: 20px;
  }

  .student-name {
    font-size: 18px;
    margin-bottom: 12px;
    color: var(--medium-gray);
  }

  .bottom-row {
    padding-top: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    .total {
      font-size: 20px;
      color: var(--medium-gray);
    }

    .update-materials-button {
      margin-right: 12px;
    }

    > :not(:last-child) {
      margin-right: 12px;
    }
  }

  .why-late-fee {
    margin-top: 16px;
    color: var(--medium-gray);
    font-size: 12px;

    .why-title {
      font-weight: 600;
    }

    .why-explanation {
      margin-top: 4px;
    }
  }
}

@media screen and (max-width: 568px) {
  .checkout-late-fees-item {
    margin: 0px;
    padding: 12px;

    &:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 24px;
    }

    .bottom-row {
      flex-direction: column;

      .total {
        margin-bottom: 16px;
      }
    }

    .why-late-fee {
      margin-top: 0px;
    }
  }
}
