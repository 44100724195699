.complete-password-reset {
  position: relative;
  height: var(--content-height);
  width: 100vw;
  padding: 48px 84px 84px;
  display: flex;
  background-color: var(--background);

  .left-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hero-text {
      width: 75%;
      margin-bottom: 16px;
      color: var(--dark-primary);
      font-size: 60px;
      line-height: 60px;
      font-family: Pockota;
      font-weight: 600;
      text-align: center;

      + .hero-text {
        margin-top: -12px;
      }
    }

    .helper-text {
      width: 75%;
      margin-top: 0px;
      margin-bottom: 32px;
      color: var(--gray);
      font-size: 20px;
      line-height: 28px;
    }

    .buttons-row {
      width: 75%;
      display: flex;
      align-items: center;

      .cta-button-wrapper {
        flex-shrink: 0;
      }

      .or {
        margin: 0px 24px;
      }
    }
  }

  .right-col {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .complete-password-reset-content {
      position: relative;
      z-index: 1;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .complete-password-reset-square {
        // height: 440px;
        width: 382px;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: var(--big-shadow);

        .complete-password-reset-logo {
          width: 70%;
        }

        .complete-password-reset-inputs {
          width: 322px;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;

          .inputs-row-title {
            width: 100%;
            margin-bottom: 6px;
            color: var(--medium-gray);
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.75px;
          }

          .inputs-row-subtitle {
            width: 100%;
            margin-top: -4px;
            margin-bottom: 12px;
            color: var(--medium-gray);
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 0.75px;
          }

          .inputs-group {
            width: 100%;
            margin-bottom: 32px;

            &.last {
              margin-bottom: 16px;
            }

            .lpm-input + .lpm-input {
              margin-top: 12px;
            }

            .error-container {
              margin: 8px;

              .error-text {
                font-size: 12px;
                color: var(--red);
              }
            }
          }
        }

        .send-code-button {
          width: 322px;
          margin-top: 32px;
        }

        .lpm-input-error + .auth-button {
          margin-top: 8px;
        }

        .complete-password-reset-link {
          margin-top: 16px;
          font-size: 14px;
          font-weight: 800;
          line-height: 22px;
          cursor: pointer;
          user-select: none;

          &:active {
            opacity: 0.2;
          }

          a {
            color: var(--orange);
          }
        }

        .no-account {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 64px;
          margin-bottom: 24px;
          font-size: 14px;
          font-weight: 800;
          line-height: 22px;
          user-select: none;

          > span {
            color: initial;
            opacity: 0.3;
          }

          .sign-up-button {
            width: 322px;
            margin-top: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .complete-password-reset {
    height: auto;
    min-height: var(--content-height);
    padding: 0px 20px;
    display: block;

    .left-col {
      padding-top: 36px;

      .hero-text {
        width: 100%;
        margin: 0px;
        margin-bottom: 24px;
        font-size: 40px;
        line-height: 40px;
      }

      .helper-text {
        width: 100%;
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
      }
    }

    .right-col {
      display: block;
      padding-bottom: 36px;

      .complete-password-reset-content {
        .complete-password-reset-square {
          width: 90vw;
          margin-top: 8px;

          .complete-password-reset-inputs {
            width: calc(100% - 40px);
          }

          .send-code-button {
            width: 100%;
          }

          .lpm-input-error + .complete-password-reset-button {
            margin-top: 8px;
          }

          .no-account {
            width: calc(100% - 40px);
            margin-top: 36px;

            .sign-up-button {
              width: 100%;
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
