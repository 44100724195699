.enter-registration-code-modal {
  position: relative;
  padding: 24px 48px;
  width: 550px;
  min-height: 280px;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  overflow-y: scroll;

  &.small {
    min-height: 0px;
  }

  .content {
    flex-grow: 1;
  }

  .title {
    margin-bottom: 16px;
    font-size: 32px;
  }

  .input-wrapper {
    max-width: 75%;
    // margin-bottom: 24px;

    .input-title {
      margin-bottom: 6px;
      color: var(--medium-gray);
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.75px;
    }
  }

  .message {
    max-width: 440px;
    margin-bottom: 24px;
    font-size: 18px;
    color: var(--medium-gray);
  }

  .bottom-buttons {
    flex-shrink: 0;
    height: 60px;
    width: 100%;
    margin-top: 24px;
    padding: 0px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-buttons {
      display: flex;

      > :not(:last-child) {
        margin-right: 20px;
      }
    }

    .send-request-button {
      height: 40px;
      width: 142px;

      &.button-loading {
        opacity: 0.3;
      }
    }
  }
}

@media screen and (max-width: 568px) {
  .enter-registration-code-modal {
    padding: 20px;
    width: calc(100vw - 40px);
    height: auto;

    .title {
      font-size: 20px;
      font-weight: 600;
    }

    .input-wrapper {
      max-width: 100%;
      margin-bottom: 0px;
    }

    .inputs-row-title {
      font-size: 12px;
    }

    .message-wrapper {
      .message {
        font-size: 14px;
      }
    }

    .bottom-buttons {
      padding: 0px;
    }
  }
}
